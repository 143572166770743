<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
    :expand-on-hover="expandOnHover"
    :right="$vuetify.rtl"
    :src="barImage"
    mobile-break-point="960"
    app
    width="260"
    v-bind="$attrs"
  >
    <template v-slot:img="props">
      <v-img
        :gradient="`to bottom, ${barColor}`"
        v-bind="props"
      />
    </template>

    <v-divider class="mb-1" />

    <v-list
      dense
      nav
    >
      <v-list-item>
        <v-list-item-avatar
          class="align-self-center"
          color="white"
          contain
        >
          <v-img
            src="favicon.ico"
            max-height="30"
            max-width="30"
          />
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title
            class="text-h4"
            v-text="profile.title"
          />
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-divider class="mb-2" />

    <v-list
      expand
      nav
    >
      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />

      <template v-for="(item, i) in computedItems">
        <base-item-group
          v-if="item.children"
          :key="`group-${i}`"
          :item="item"
        >
          <!--  -->
        </base-item-group>

        <base-item
          v-else
          :key="`item-${i}`"
          :item="item"
        />
      </template>
      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />
    </v-list>

    <!--    <template v-slot:append>-->
    <!--      <base-item-->
    <!--        :item="{-->
    <!--          title: $t('upgrade'),-->
    <!--          icon: 'mdi-package-up',-->
    <!--          to: '/upgrade',-->
    <!--        }"-->
    <!--      />-->
    <!--    </template>-->
  </v-navigation-drawer>
</template>

<script>
  // Utilities
  import {
    mapGetters, mapState,
  } from 'vuex'

  export default {
    name: 'DashboardCoreDrawer',

    props: {
      expandOnHover: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      items: [
        {
          icon: 'mdi-view-dashboard',
          title: 'dashboard',
          to: '/',
        },
        {
          icon: 'mdi-face-agent',
          title: 'Tickets d\'assistance',
          to: '/helpdesk',
          module: 'HelpDesk', // Utile pour filtrer le menu en fonction des droits
        },
        {
          icon: 'mdi-domain',
          title: 'Mes sociétés',
          to: '/accounts',
          module: 'Accounts', // Utile pour filtrer le menu en fonction des droits
        },
        {
          icon: 'mdi-clipboard-text',
          title: 'Mes contrats',
          to: '/servicecontracts',
          module: 'ServiceContracts', // Utile pour filtrer le menu en fonction des droits
        },
        {
          icon: 'mdi-server',
          title: 'Parc matériel',
          to: '/parcmateriel',
          module: 'ParcMateriel', // Utile pour filtrer le menu en fonction des droits
        },
        {
          icon: 'mdi-laptop',
          title: 'Parc logiciel',
          to: '/parclogiciel',
          module: 'ParcLogiciel', // Utile pour filtrer le menu en fonction des droits
        },
        {
          icon: 'mdi-account-multiple',
          title: 'Mes collaborateurs',
          to: '/contacts',
          module: 'Contacts', // Utile pour filtrer le menu en fonction des droits
        },
        // {
        //   icon: 'mdi-code-tags',
        //   title: 'Vue générique (R)',
        //   to: '/vuegenericreadonly',
        // },
        // {
        //   icon: 'mdi-code-tags',
        //   title: 'Vue générique (RW)',
        //   to: '/vuegenericform',
        // },
        // {
        //   icon: 'mdi-account',
        //   title: 'user',
        //   to: '/pages/user',
        // },
        // {
        //   title: 'rtables',
        //   icon: 'mdi-clipboard-outline',
        //   to: '/tables/regular-tables',
        // },
        // {
        //   title: 'typography',
        //   icon: 'mdi-format-font',
        //   to: '/components/typography',
        // },
        // {
        //   title: 'icons',
        //   icon: 'mdi-chart-bubble',
        //   to: '/components/icons',
        // },
        // {
        //   title: 'google',
        //   icon: 'mdi-map-marker',
        //   to: '/maps/google-maps',
        // },
        // {
        //   title: 'notifications',
        //   icon: 'mdi-bell',
        //   to: '/components/notifications',
        // },
      ],
    }),

    computed: {
      ...mapState(['barColor', 'barImage']),
      ...mapGetters(['user']),
      drawer: {
        get () {
          return this.$store.state.drawer
        },
        set (val) {
          this.$store.commit('SET_DRAWER', val)
        },
      },
      computedItems () {
        return this.items.filter(this.filterItems).map(this.mapItem)
      },
      profile () {
        return {
          avatar: true,
          title: this.$store.getters.appTitle,
        }
      },
    },

    methods: {
      filterItems (item) {
        if (typeof item.module !== 'undefined' && item.module.length > 0) {
          if (!this.user.cf_1832.match(new RegExp(item.module, 'i'))) {
            return false
          }
        }

        return true
      },
      mapItem (item) {
        return {
          ...item,
          children: item.children ? item.children.map(this.mapItem) : undefined,
          title: this.$t(item.title),
        }
      },
    },
  }
</script>

<style lang="sass">
  @import '~vuetify/src/styles/tools/_rtl.sass'

  #core-navigation-drawer
    .v-list-group__header.v-list-item--active:before
      opacity: .24

    .v-list-item
      &__icon--text,
      &__icon:first-child
        justify-content: center
        text-align: center
        width: 20px

        +ltr()
          margin-right: 24px
          margin-left: 12px !important

        +rtl()
          margin-left: 24px
          margin-right: 12px !important

    .v-list--dense
      .v-list-item
        &__icon--text,
        &__icon:first-child
          margin-top: 10px

    .v-list-group--sub-group
      .v-list-item
        +ltr()
          padding-left: 8px

        +rtl()
          padding-right: 8px

      .v-list-group__header
        +ltr()
          padding-right: 0

        +rtl()
          padding-right: 0

        .v-list-item__icon--text
          margin-top: 19px
          order: 0

        .v-list-group__header__prepend-icon
          order: 2

          +ltr()
            margin-right: 8px

          +rtl()
            margin-left: 8px
</style>
